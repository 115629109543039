// Here you can add other styles
@import '@fontsource/pt-sans';
@import "@fontsource/rajdhani";

html,
body {
  font-family: 'PT Sans';
  color: $dark !important;
  background-color: #ebedef;
}

button svg {
  pointer-events: none;
}

.accordion-button svg {
  pointer-events: inherit;
}

.logo {
  font-family: "Rajdhani";
}

.logo-gradient {
  background: -webkit-linear-gradient(rgba(44, 9, 142, 0.9), rgba(88, 29, 135, 0.9), rgba(152, 42, 177, 0.9));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sortable-fallback {
  opacity: 1.0 !important;
}

.sortable-ghost {
  visibility: hidden;
}